<template>
  <CustomCollapseComponent :title="titre">
    <b-row class="mx-2 mx-md-5 py-2 py-md-3">
      <b-col>
        <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
        <b-row v-if="criteres">
          <b-col v-for="(col, index) in grille" :key="index">
            <template v-for="critere in col">
              <SelectRecolteComponent
                v-if="critere.name === 'SELECT_RECOLTE'"
                :key="critere.name"
                label="Campagne"
                :labelCols="{ md: 6 }"
                v-model="campagne"
              ></SelectRecolteComponent>
              <component
                v-else
                :is="critere.component"
                :key="critere.name"
                :selected="criteres[critere.name].selected"
                @select="(value) => (criteres[critere.name].selected = value)"
                :campagne="campagne"
                :params="criteres[critere.name].parametres"
                @inputParams="
                  (value) => (criteres[critere.name].parametres = value)
                "
              ></component>
            </template>
          </b-col>
        </b-row>
        <b-button-toolbar>
          <b-button pill variant="primary" @click="searchContacts">
            <b-icon-search></b-icon-search>
            &nbsp;Rechercher les contacts
          </b-button>
          &nbsp;
          <b-button-group>
            <b-button
              class="rounded-left"
              variant="outline-primary"
              @click="copierEmails"
            >
              <b-icon-at></b-icon-at>
              &nbsp;Copier les adresses mails
            </b-button>
            <b-button
              class="rounded-right"
              variant="outline-primary"
              @click="copierTelephones"
            >
              <b-icon-telephone></b-icon-telephone>
              &nbsp;Copier les num&eacute;ros de t&eacute;l&eacute;phone
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
  </CustomCollapseComponent>
</template>

<script>
import CustomCollapseComponent from "../controls/CustomCollapseComponent.vue";
import ErrorComponent from "../ErrorComponent.vue";
import SelectRecolteComponent from "../inputs/SelectRecolteComponent.vue";
import CritereApporteur from "./criteres/CritereApporteur.vue";
import CritereDateAdhesion from "./criteres/CritereDateAdhesion.vue";
import CritereDepartement from "./criteres/CritereDepartement.vue";
import CritereDistance from "./criteres/CritereDistance.vue";
import CritereEspece from "./criteres/CritereEspece.vue";
import CritereFonction from "./criteres/CritereFonction.vue";
import CriterePortefeuille from "./criteres/CriterePortefeuille.vue";
import CritereProduit from "./criteres/CritereProduit.vue";
import CritereRecolte from "./criteres/CritereRecolte.vue";
import CritereSemis from "./criteres/CritereSemis.vue";
import CritereTypeTiers from "./criteres/CritereTypeTiers.vue";
import CritereVariete from "./criteres/CritereVariete.vue";
import CommunicationService, {
  CritereCommunication,
  methods,
} from "../../services/communication.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ContactSelectionComponent",
  components: {
    CustomCollapseComponent,
    ErrorComponent,
    SelectRecolteComponent,
    CritereApporteur,
    CritereDateAdhesion,
    CritereDepartement,
    CritereDistance,
    CritereEspece,
    CritereFonction,
    CriterePortefeuille,
    CritereProduit,
    CritereRecolte,
    CritereSemis,
    CritereTypeTiers,
    CritereVariete,
  },
  props: { filtres: { type: Array, default: () => [] } },
  data: () => ({
    visible: false,
    error: null,
    grille: [
      [
        CritereCommunication.TYPE_TIERS,
        CritereCommunication.DATE_ADHESION,
        CritereCommunication.DEPARTEMENT,
        CritereCommunication.DISTANCE,
        CritereCommunication.FONCTION_CONTACT,
      ],
      [
        { name: "SELECT_RECOLTE", component: null },
        CritereCommunication.TIERS_PORTEFEUILLE,
        CritereCommunication.TIERS_APPORTEUR,
        CritereCommunication.ESPECE,
        CritereCommunication.PRODUIT,
        CritereCommunication.VARIETE,
        CritereCommunication.SEMIS,
        CritereCommunication.RECOLTE,
      ],
    ],
    campagne: null,
    criteres: null,
    tiers: [],
  }),
  computed: {
    societe() {
      return this.$store.getters["societe"];
    },
    contacts() {
      return this.tiers.flatMap((t) => t.contacts);
    },
    emails() {
      return this.contacts.filter((c) => !!c.email).map((c) => c.email);
    },
    telephones() {
      return this.contacts.filter((c) => !!c.telephone).map((c) => c.telephone);
    },
    titre() {
      return `Destinataires : ${this.tiers.length} tiers (${this.emails.length} mails, ${this.telephones.length} téléphones)`;
    },
  },
  async created() {
    // Initialiser les critères pris en charge
    let criteres = {};
    Object.keys(CritereCommunication).forEach((codeCritere) => {
      let filtre = this.filtres.find((f) => f.codeCritere === codeCritere);
      if (filtre) {
        // Soit on prend le filtre fourni en prop
        criteres[codeCritere] = UtilsService.deepCopy(filtre);
        criteres[codeCritere].selected = true;
      } else {
        // Soit on prend les valeurs par défaut
        criteres[codeCritere] = {
          codeCritere,
          selected:
            this.filtres.length === 0 &&
            CritereCommunication[codeCritere].defaultValue.selected,
          parametres: [
            ...CritereCommunication[codeCritere].defaultValue.parametres.map(
              (p) => methods.emptyFiltreParam(p)
            ),
          ],
        };
      }
    });
    this.criteres = criteres;
  },
  async mounted() {
    // Actualiser la liste des contacts
    await this.searchContacts();
  },
  methods: {
    show() {
      this.visible = true;
    },
    toggle() {
      this.visible = !this.visible;
    },
    hide() {
      this.visible = false;
    },
    async searchContacts() {
      this.error = null;
      try {
        let { data: tiers } = await CommunicationService.searchContacts(
          this.societe.code,
          Object.values(this.criteres).filter((c) => !!c.selected)
        );
        this.tiers = tiers;
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    copierEmails() {
      document.defaultView.navigator.clipboard.writeText(this.emails.join(";"));
    },
    copierTelephones() {
      document.defaultView.navigator.clipboard.writeText(
        this.telephones.join(",")
      );
    },
    getTiers() {
      return UtilsService.deepCopy(this.tiers);
    },
    getFiltres() {
      return UtilsService.deepCopy(
        Object.values(this.criteres).filter((c) => !!c.selected)
      );
    },
  },
};
</script>
