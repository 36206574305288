<template>
  <section>
    <TitleBar>
      Assistant de communication cibl&eacute;e
      <template #toolbar-content>
        <b-button-group>
          <b-button pill variant="outline-primary" @click="cancel">
            <b-icon-arrow-left></b-icon-arrow-left>
            Retour
          </b-button>
        </b-button-group>
      </template>
    </TitleBar>
    <b-container v-if="communication" fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <ContactSelectionComponent
        ref="contactsSelector"
        :filtres="communication.filtres"
      ></ContactSelectionComponent>
      <CustomInputComponent
        label="Cc : "
        :labelCols="{ md: 'auto' }"
        v-model="communication.copyTo"
      ></CustomInputComponent>
      <CustomCollapseComponent ref="mailCollapse" title="Canal email">
        <b-row class="mx-2 mx-md-5 py-2 py-md-3">
          <b-col>
            <SelectTemplateMailComponent
              ref="selectTemplate"
              label="Template : "
              :labelCols="{ md: 2 }"
              :societe="societe"
              :value="communication.idTemplate"
              @input="(value) => templateChanged(value)"
            ></SelectTemplateMailComponent>
            <CustomInputComponent
              ref="inputObjet"
              label="Objet : "
              :labelCols="{ md: 2 }"
              rules="required"
              v-model="communication.objet"
            ></CustomInputComponent>
            <template v-if="template">
              <b-form-group
                v-for="i in template.nombreSlots"
                :key="i"
                :id="`group-${i}-${id}`"
                :label="`Emplacement ${i}`"
                label-class="col-form-label"
                :label-for="`input-${i}-${id}`"
                :description="`Zone de texte n°${i} du template`"
              >
                <b-form-textarea
                  :id="`input-${i}-${id}`"
                  :name="`input-${i}-${id}`"
                  :rows="10"
                  v-model="communication.contenus[i - 1].contenu"
                ></b-form-textarea>
              </b-form-group>
            </template>
          </b-col>
        </b-row>
      </CustomCollapseComponent>
      <b-row class="mb-3" align-h="center">
        <b-col cols="auto">
          <b-button-toolbar>
            <b-button-group>
              <b-button pill variant="primary" @click="enregistrer()">
                Enregistrer
              </b-button>
              <!-- TODO Ajouter un bouton "Enregistrer sous" pour faire une copie ? -->
            </b-button-group>
            &nbsp;
            <b-button-group>
              <b-button pill variant="danger" @click="envoyer()">
                Envoyer
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-container>
    <CommunicationValidationDialog
      ref="communicationValidationDialog"
    ></CommunicationValidationDialog>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import TitleBar from "../../components/TitleBar.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import ContactSelectionComponent from "../../components/communication/ContactSelectionComponent.vue";
import CustomInputComponent from "../../components/inputs/CustomInputComponent.vue";
import CustomCollapseComponent from "../../components/controls/CustomCollapseComponent.vue";
import SelectTemplateMailComponent from "../../components/communication/SelectTemplateMailComponent.vue";
import CommunicationValidationDialog from "../../components/communication/CommunicationValidationDialog.vue";
import CommunicationService, {
  methods,
} from "../../services/communication.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "AssistantView",
  components: {
    TitleBar,
    ErrorComponent,
    ContactSelectionComponent,
    CustomInputComponent,
    CustomCollapseComponent,
    SelectTemplateMailComponent,
    CommunicationValidationDialog,
  },
  data: () => ({
    id: uuidv4(),
    error: null,
    communication: null,
    template: null,
  }),
  computed: {
    societe() {
      return this.$store.getters['societe'].code;
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      this.error = null;
      this.communication = null;
      this.template = null;

      // Charger une nouvelle communication vide
      if (!this.$route.params.idTemplate) {
        this.communication = methods.emptyCommunication(this.societe);
        return;
      }

      try {
        // Charger la communication existante
        let { data: communication } =
          await CommunicationService.getCommunication(
            this.$route.params.idTemplate
          );
        this.communication = communication;

        // Charger le template mail lié
        this.templateChanged(this.communication.idTemplate);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    async templateChanged(idTemplate) {
      this.error = null;
      this.template = null;

      if (!idTemplate) {
        this.communication.idTemplate = null;
        return;
      }

      try {
        // Récupérer le nouveau template
        let { data: template } = await CommunicationService.getTemplateMail(
          idTemplate
        );

        // Mettre à jour l'objet communication et ses zones de contenu
        this.communication.idTemplate = template.id;
        this.communication.contenus = UtilsService.range(
          template.nombreSlots
        ).map(
          (_, i) => this.communication.contenus[i] ?? methods.emptyContenu()
        );

        // Affecter le template pour rouvrir l'écran de saisie
        this.template = template;
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    async validate() {
      try {
        await this.$refs.selectTemplate.validate();
        await this.$refs.inputObjet.validate();
      } catch (e) {
        this.$refs.mailCollapse.show();

        // Attendre que le machin s'ouvre pour revalider...
        await this.$nextTick();
        await this.$refs.selectTemplate.validate();
        await this.$refs.inputObjet.validate();
      }
    },
    async enregistrer() {
      // Validation du formulaire
      await this.validate();

      this.error = null;
      try {
        // Préparation des données
        this.communication.filtres = this.$refs.contactsSelector.getFiltres();
        this.communication.contacts = [];
        this.communication.contenus.splice(this.template.nombreSlots);

        // Envoi à l'API
        await CommunicationService.createUpdateTemplate(this.communication);
        this.$router.push({ name: "com-templates" });
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    async envoyer() {
      // Validation du formulaire
      await this.validate();

      // Rafraichissement de la liste des destinataires
      await this.$refs.contactsSelector.searchContacts();

      // Ouvrir la popup de filtrage des destinataires
      let contacts = await this.$refs.communicationValidationDialog.show({
        tiers: this.$refs.contactsSelector.getTiers(),
      });
      if (!contacts?.length) {
        return;
      }

      this.error = null;
      try {
        // Préparation des données
        this.communication.filtres = this.$refs.contactsSelector.getFiltres();
        this.communication.contacts = contacts.map((c) => ({
          codeContact: c.codeContact,
          selected: c.selected,
        }));
        this.communication.contenus.splice(this.template.nombreSlots);

        // Envoi à l'API
        await CommunicationService.sendCommunication(this.communication);
        this.$router.push({ name: "com-historique" });
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
    },
    cancel() {
      this.$router.push({ name: "com-templates" });
    },
  },
};
</script>
